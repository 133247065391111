import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

const initialState = {
  types: [],
  categories: [],
  loading: false,
  error: null,
};

const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getAttributeTypesSuccess(state, action) {
      state.loading = false;
      state.types = action.payload;
    },
    getAttributeCategoriesSuccess(state, action) {
      state.loading = false;
      state.categories = action.payload;
    },
  },
});

export const { startLoading, hasError, getAttributeTypesSuccess, getAttributeCategoriesSuccess } = attributeSlice.actions;
export default attributeSlice.reducer;

export const getAttributeTypes = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosInstance.get('/producttype');
    dispatch(getAttributeTypesSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
}

export const getAttributeCategories = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosInstance.get('/productcategory');
    dispatch(getAttributeCategoriesSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
}