// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    topSpender: path(ROOTS_DASHBOARD, '/user/top-spender'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  collect: {
    root: path(ROOTS_DASHBOARD, '/collect'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    listProduct: path(ROOTS_DASHBOARD, '/e-commerce/list-product'),
    listCask: path(ROOTS_DASHBOARD, '/e-commerce/list-cask'),
    listAuction: path(ROOTS_DASHBOARD, '/e-commerce/list-auction'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    newAuction: path(ROOTS_DASHBOARD, '/e-commerce/auction/new'),
    place_your_bid: path(ROOTS_DASHBOARD, '/e-commerce/auction/place-your-bid'),
    logAuction: path(ROOTS_DASHBOARD, '/e-commerce/auction/log'),
    selectAuction: path(ROOTS_DASHBOARD, '/e-commerce/auction/select'),
    stockAvailable: path(ROOTS_DASHBOARD, '/e-commerce/stock-available'),
    newCask: path(ROOTS_DASHBOARD, '/e-commerce/cask/new'),
    editCask: path(ROOTS_DASHBOARD, '/e-commerce/cask/:name/edit'),
  },
  cask: {
    root: path(ROOTS_DASHBOARD, '/cask'),
    newCask: path(ROOTS_DASHBOARD, '/cask/new'),
    listCask: path(ROOTS_DASHBOARD, '/cask/list'),
    editCask: path(ROOTS_DASHBOARD, '/cask/:name/edit'),
  },
  auction: {
    root: path(ROOTS_DASHBOARD, '/auction'),
    list: path(ROOTS_DASHBOARD, '/auction/list'),
    new: path(ROOTS_DASHBOARD, '/auction/new'),
    place_your_bid: path(ROOTS_DASHBOARD, '/auction/place-your-bid'),
    log: path(ROOTS_DASHBOARD, '/auction/log'),
    select: path(ROOTS_DASHBOARD, '/auction/select'),
    schedule: path(ROOTS_DASHBOARD, '/auction/schedule'),
  },
  attribute: {
    root: path(ROOTS_DASHBOARD, '/attribute'),
    productClass: path(ROOTS_DASHBOARD, '/attribute/product-class'),
    productType: path(ROOTS_DASHBOARD, '/attribute/product-type'),
    productCategoryDram: path(ROOTS_DASHBOARD, '/attribute/product-category/dram'),
    productCategoryBottle: path(ROOTS_DASHBOARD, '/attribute/product-category/bottle'),
    productDistillery: path(ROOTS_DASHBOARD, '/attribute/product-distillery'),
    productLottype: path(ROOTS_DASHBOARD, '/attribute/product-lottype'),
    productRegion: path(ROOTS_DASHBOARD, '/attribute/product-region'),
    productLocation: path(ROOTS_DASHBOARD, '/attribute/product-location'),
  },

  settings: {
    root: path(ROOTS_DASHBOARD, '/setting'),
    general: path(ROOTS_DASHBOARD, '/setting/general'),
    email: path(ROOTS_DASHBOARD, '/setting/email'),
    payment: path(ROOTS_DASHBOARD, '/setting/payment'),
    shipping: path(ROOTS_DASHBOARD, '/setting/shipping'),
    tax: path(ROOTS_DASHBOARD, '/setting/tax'),
    company: path(ROOTS_DASHBOARD, '/setting/company'),
    social: path(ROOTS_DASHBOARD, '/setting/social'),
    seo: path(ROOTS_DASHBOARD, '/setting/seo'),
    content: path(ROOTS_DASHBOARD, '/setting/content'),
    banner: path(ROOTS_DASHBOARD, '/setting/banner'),
  },

  content: {
    root: path(ROOTS_DASHBOARD, '/content'),
  },

  order: {
    root: path(ROOTS_DASHBOARD, '/order'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    new: path(ROOTS_DASHBOARD, '/order/new'),
    storage: path(ROOTS_DASHBOARD, '/order/storage'),
  },

  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
    editPost: path(ROOTS_DASHBOARD, '/blog/new-post/:id/edit'),
  },

  comment: {
    root: path(ROOTS_DASHBOARD, '/comment'),
    list: path(ROOTS_DASHBOARD, '/comment/list'),
  },

  submitandshare: {
    root: path(ROOTS_DASHBOARD, '/submit-share'),
    list: path(ROOTS_DASHBOARD, '/submit-share/list'),
    seller: path(ROOTS_DASHBOARD, '/submit-share/seller'),
    requestValuation: path(ROOTS_DASHBOARD, '/submit-share/request-valuation'),
  },

  reviewandrating: {
    root: path(ROOTS_DASHBOARD, '/review-rating'),
    create: path(ROOTS_DASHBOARD, '/review-rating/create'),
    list: path(ROOTS_DASHBOARD, '/review-rating/list'),
    send: path(ROOTS_DASHBOARD, '/review-rating/send'),
    listProduct: path(ROOTS_DASHBOARD, '/review-rating/list-product'),
    edit: path(ROOTS_DASHBOARD, '/review-rating/:id/edit'),
  },

  shippings: {
    root: path(ROOTS_DASHBOARD, '/shipping'),
    list: path(ROOTS_DASHBOARD, '/shipping/list'),
    girds: path(ROOTS_DASHBOARD, '/shipping/list/girds'),
    girdsdram: path(ROOTS_DASHBOARD, '/shipping/list/girds/dram'),
    girdsbottle: path(ROOTS_DASHBOARD, '/shipping/list/girds/bottle'),
    dram: path(ROOTS_DASHBOARD, '/shipping/list/dram'),
    bottle: path(ROOTS_DASHBOARD, '/shipping/list/bottle'),
    new: path(ROOTS_DASHBOARD, '/shipping/new'),
    edit: path(ROOTS_DASHBOARD, '/shipping/:id/edit'),
  },

  newsletter: {
    root: path(ROOTS_DASHBOARD, '/newsletter'),
    list: path(ROOTS_DASHBOARD, '/newsletter/list'),
    new: path(ROOTS_DASHBOARD, '/newsletter/new'),
    newsletters: path(ROOTS_DASHBOARD, '/newsletter/newsletters'),
    create_template: path(ROOTS_DASHBOARD, '/newsletter/create'),
    edit_template: path(ROOTS_DASHBOARD, '/newsletter/template/:id/edit'),
    send_email: path(ROOTS_DASHBOARD, '/newsletter/send'),
    subscribers: path(ROOTS_DASHBOARD, '/newsletter/subscribers'),
    unsubscribe: path(ROOTS_DASHBOARD, '/newsletter/unsubscribe'),
  },

  glossary: {
    root: path(ROOTS_DASHBOARD, '/glossary'),
    list: path(ROOTS_DASHBOARD, '/glossary/list'),
    new: path(ROOTS_DASHBOARD, '/glossary/new'),
    edit: path(ROOTS_DASHBOARD, '/glossary/:id/edit'),
  },

  library: {
    root: path(ROOTS_DASHBOARD, '/library'),
  },

  wishlist: {
    root: path(ROOTS_DASHBOARD, '/wishlist'),
    auction: path(ROOTS_DASHBOARD, '/wishlist/auction'),
    shop: path(ROOTS_DASHBOARD, '/wishlist/shop'),
    requestAvailable: path(ROOTS_DASHBOARD, '/wishlist/request-available'),
    requestForPrice: path(ROOTS_DASHBOARD, '/wishlist/request-for-price'),
  },

  faq: {
    root: path(ROOTS_DASHBOARD, '/faq'),
    list: path(ROOTS_DASHBOARD, '/faq/list'),
    new: path(ROOTS_DASHBOARD, '/faq/new'),
    edit: path(ROOTS_DASHBOARD, '/faq/:id/edit'),
  },

  independent: {
    root: path(ROOTS_DASHBOARD, '/independent'),
    list: path(ROOTS_DASHBOARD, '/independent/list'),
    new: path(ROOTS_DASHBOARD, '/independent/new'),
    edit: path(ROOTS_DASHBOARD, '/independent/:id/edit'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
