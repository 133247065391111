// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import axiosInstance from 'src/utils/axios';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  order: getIcon('ic_order'),
  attribute: getIcon('ic_attribute'),
  submitandshare: getIcon('ic-submit-share'),
  reviewandrating: getIcon('ic_review-rating'),
  shippings: getIcon('ic_shippings'),
  settings: getIcon('ic_setting'),
  newsletter: getIcon('ic_newsletter'),
  glossary: getIcon('ic_glossary'),
  content: getIcon('ic_content'),
  banner: getIcon('ic_banner'),
  product: getIcon('ic_product'),
  auction: getIcon('ic_auction'),
  library: getIcon('ic_library'),
  wishlist: getIcon('ic_wishlist'),
  independent: getIcon('ic_independent'),
  faq: getIcon('ic_faq'),
  collect: getIcon('ic_collect'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        role: ['admin'],
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'list user card', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'top spender', path: PATH_DASHBOARD.user.topSpender }
          // { title: 'create', path: PATH_DASHBOARD.user.newUser },
          // { title: 'edit', path: PATH_DASHBOARD.user.editById },
          // { title: 'account', path: PATH_DASHBOARD.user.editById },
        ],
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: 'Product',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.product,
        role: ['admin', 'cask'],
        children: [
          { title: 'product list', path: PATH_DASHBOARD.eCommerce.listProduct, role: ['admin'] },
          { title: 'cask list', path: PATH_DASHBOARD.eCommerce.listCask, role: ['cask'] },
          { title: 'create product', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'stock available', path: PATH_DASHBOARD.eCommerce.stockAvailable },
          { title: 'create cask', path: PATH_DASHBOARD.eCommerce.newCask, role: ['cask', 'admin'] },
        ],
      },
      {
        title: 'Auction',
        path: PATH_DASHBOARD.auction.root,
        icon: ICONS.auction,
        role: ['admin'],
        children: [
          { title: 'auction list', path: PATH_DASHBOARD.auction.list },
          { title: 'create auction', path: PATH_DASHBOARD.auction.new },
          { title: 'Incremental Bid', path: PATH_DASHBOARD.auction.place_your_bid },
          { title: 'auction log', path: PATH_DASHBOARD.auction.log },
          { title: 'Show auction on Home', path: PATH_DASHBOARD.auction.select },
          { title: 'Schedule', path: PATH_DASHBOARD.auction.schedule },
        ],
      },
      {
        title: 'Wishlist',
        role: ['admin'],
        path: PATH_DASHBOARD.wishlist.root,
        icon: ICONS.wishlist,
      },
      {
        title: 'attribute',
        role: ['admin'],
        path: PATH_DASHBOARD.attribute.root,
        icon: ICONS.attribute,
        children: [
          { title: 'Product Class', path: PATH_DASHBOARD.attribute.productClass },
          { title: 'Product Type', path: PATH_DASHBOARD.attribute.productType },
          {
            title: 'Product Category',
            // path: PATH_DASHBOARD.attribute.productCategory.root,
            children: [
              { title: 'Bottle Shop', path: PATH_DASHBOARD.attribute.productCategoryBottle },
              { title: 'Dram Shop', path: PATH_DASHBOARD.attribute.productCategoryDram },
            ],
          },
          { title: 'Product Distillery', path: PATH_DASHBOARD.attribute.productDistillery },
          { title: 'Product Lottype', path: PATH_DASHBOARD.attribute.productLottype },
          { title: 'Product Region', path: PATH_DASHBOARD.attribute.productRegion },
          { title: 'Product Location', path: PATH_DASHBOARD.attribute.productLocation },
        ]
      },

      {
        title: 'order',
        role: ['admin'],
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.order,
        children: [
          { title: 'order list', path: PATH_DASHBOARD.order.list },
          { title: 'Storage Orders', path: PATH_DASHBOARD.order.storage },
        ],
      },

      // MANAGEMENT : BLOG
      {
        title: 'News Article',
        role: ['admin'],
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'List News Article', path: PATH_DASHBOARD.blog.posts },
          // { title: 'News Article Detail', path: PATH_DASHBOARD.blog.postById },
          { title: 'Create New Article', path: PATH_DASHBOARD.blog.newPost },
        ],
      },

      // MANAGEMENT : COMMENTS
      {
        title: 'comments',
        role: ['admin'],
        path: PATH_DASHBOARD.comment.root,
        icon: ICONS.chat,
        children: [
          { title: 'list', path: PATH_DASHBOARD.comment.list },
        ],
      },

      // MANAGEMENT : Submit and Share
      {
        title: 'Request Lots',
        role: ['admin'],
        path: PATH_DASHBOARD.submitandshare.root,
        icon: ICONS.submitandshare,
        children: [
          { title: 'Submit Lots', path: PATH_DASHBOARD.submitandshare.list },
          { title: 'Request Valuation', path: PATH_DASHBOARD.submitandshare.requestValuation },
          { title: 'Seller Management', path: PATH_DASHBOARD.submitandshare.seller },
        ],
      },

      // MANAGEMENT : Review and Rating
      {
        title: 'Review',
        role: ['admin'],
        path: PATH_DASHBOARD.reviewandrating.root,
        icon: ICONS.reviewandrating,
        children: [
          { title: 'create', path: PATH_DASHBOARD.reviewandrating.create },
          { title: 'list', path: PATH_DASHBOARD.reviewandrating.list },
          { title: 'send', path: PATH_DASHBOARD.reviewandrating.send },
        ],
      },

      // MANAGEMENT : SHIPPINGS
      {
        title: 'shippings',
        role: ['admin'],
        path: PATH_DASHBOARD.shippings.root,
        icon: ICONS.shippings,
        children: [
          { title: 'girds Drams', path: PATH_DASHBOARD.shippings.girdsdram },
          { title: 'girds Bottles', path: PATH_DASHBOARD.shippings.girdsbottle },
          { title: 'dram', path: PATH_DASHBOARD.shippings.dram },
          { title: 'bottle', path: PATH_DASHBOARD.shippings.bottle },
          { title: 'create', path: PATH_DASHBOARD.shippings.new },
        ],
      },

      // MANAGEMENT : SETTINGS
      // {
      //   title: 'settings',
      //   path: PATH_DASHBOARD.settings.root,
      //   icon: ICONS.settings,
      //   children: [
      //     // { title: 'General', path: PATH_DASHBOARD.settings.general },
      //     { title: 'Content', path: PATH_DASHBOARD.settings.content },
      //     // { title: 'Email', path: PATH_DASHBOARD.settings.email },
      //     // { title: 'Payment', path: PATH_DASHBOARD.settings.payment },
      //     // { title: 'Shipping', path: PATH_DASHBOARD.settings.shipping },
      //     // { title: 'Tax', path: PATH_DASHBOARD.settings.tax },
      //     // { title: 'Company', path: PATH_DASHBOARD.settings.company },
      //     // { title: 'Social', path: PATH_DASHBOARD.settings.social },
      //   ],
      // },

      // MANAGEMENT : NEWSLETTER
      {
        title: 'content',
        role: ['admin'],
        // path: PATH_DASHBOARD.content.root,
        icon: ICONS.content,
        children: [
          { title: 'Content', path: PATH_DASHBOARD.content.root },
          {
            title: 'faq',
            path: PATH_DASHBOARD.faq.root,
            children: [
              { title: 'list', path: PATH_DASHBOARD.faq.list },
              { title: 'create', path: PATH_DASHBOARD.faq.new },
            ],
          },
          {
            title: 'Independent Bottlers',
            path: PATH_DASHBOARD.independent.root,
            children: [
              { title: 'list', path: PATH_DASHBOARD.independent.list },
              { title: 'create', path: PATH_DASHBOARD.independent.new },
            ],
          },
        ],
      },
      {
        title: 'collect',
        role: ['admin'],
        path: PATH_DASHBOARD.collect.root,
        icon: ICONS.collect,
      },
      {
        title: 'banner',
        role: ['admin'],
        path: PATH_DASHBOARD.settings.banner,
        icon: ICONS.banner,
      },
      {
        title: 'newsletter',
        role: ['admin'],
        path: PATH_DASHBOARD.newsletter.root,
        icon: ICONS.newsletter,
        children: [
          { title: 'Send Email', path: PATH_DASHBOARD.newsletter.list },
          { title: 'Newsletters', path: PATH_DASHBOARD.newsletter.newsletters },
          { title: 'Create Template', path: PATH_DASHBOARD.newsletter.create_template },
          { title: 'Template Email', path: PATH_DASHBOARD.newsletter.send_email },
          { title: 'Unsubscribe', path: PATH_DASHBOARD.newsletter.unsubscribe },
          // { title: 'Subscribers', path: PATH_DASHBOARD.newsletter.subscribers },
        ],
      },
      {
        title: 'glossary',
        role: ['admin'],
        path: PATH_DASHBOARD.glossary.root,
        icon: ICONS.glossary,
        children: [
          { title: 'list', path: PATH_DASHBOARD.glossary.list },
          { title: 'create', path: PATH_DASHBOARD.glossary.new },
        ],
      },
      {
        title: 'library',
        path: PATH_DASHBOARD.library.root,
        icon: ICONS.library,
      },
      // {
      //   title: 'Independent Bottlers',
      //   path: PATH_DASHBOARD.independent.root,
      //   icon: ICONS.independent,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.independent.list },
      //     { title: 'create', path: PATH_DASHBOARD.independent.new },
      //   ],
      // },
      // {
      //   title: 'faq',
      //   path: PATH_DASHBOARD.faq.root,
      //   icon: ICONS.faq,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.faq.list },
      //     { title: 'create', path: PATH_DASHBOARD.faq.new },
      //   ],
      // }
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      // {
      //   title: 'mail',
      //   path: PATH_DASHBOARD.mail.root,
      //   icon: ICONS.mail,
      //   info: (
      //     <Label variant="outlined" color="error">
      //       +32
      //     </Label>
      //   ),
      // },
      // { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // {
      //   title: 'kanban',
      //   path: PATH_DASHBOARD.kanban,
      //   icon: ICONS.kanban,
      // },
    ],
  },
];

export default navConfig;
