import axiosInstance from '../../utils/axios';
import { dispatch } from '../store';

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isLoading: false,
  statistics: [],
}

const slice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // GET STATISTIC
    getStatisticSuccess(state, action) {
      state.isLoading = false;
      state.statistics = action.payload;
    },
    updateQuantity(state, action) {
      const { keys, quantity } = action.payload;
      if (keys.length === 1) {
        state.statistics[keys[0]] -= quantity;
        if (state.statistics[keys[0]] < 0) state.statistics[keys[0]] = 0;
      } else if (keys.length === 2) {
        state.statistics[keys[0]][keys[1]] -= quantity;
        if (state.statistics[keys[0]][keys[1]] < 0) state.statistics[keys[0]][keys[1]] = 0;
      } else if (keys.length === 3) {
        state.statistics[keys[0]][keys[1]][keys[2]] -= quantity;
        if (state.statistics[keys[0]][keys[1]][keys[2]] < 0) state.statistics[keys[0]][keys[1]][keys[2]] = 0;
      } else if (keys.length === 4) {
        state.statistics[keys[0]][keys[1]][keys[2]][keys[3]] -= quantity;
        if (state.statistics[keys[0]][keys[1]][keys[2]][keys[3]] < 0) state.statistics[keys[0]][keys[1]][keys[2]][keys[3]] = 0;
      }
    }
  }
})

export const { startLoading, getStatisticSuccess, updateQuantity } = slice.actions;
export default slice.reducer;

export const getStatistic = () => {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axiosInstance.get('/statistic');
      dispatch(getStatisticSuccess(response.data));
    } catch (error) {
      console.log(error);
    }
  }
}

