import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Collapse } from '@mui/material';
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  isCollapse: PropTypes.bool,
  list: PropTypes.object,
};

export function NavListRoot({ list, isCollapse, statistic, role }) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  const newList = useMemo(() => {
    const keys = Object.keys(statistic);

    if (keys.length > 0) {
      const listTitleExists = keys.includes(list.title);
      let rootInfo = '';

      if (list.children && listTitleExists) {

        list.children.forEach((item) => {
          if (statistic[list.title]?.[item.title] !== undefined) {
            item.info = <span style={{ color: "red" }}>({statistic[list.title][item.title]})</span>;
            if (rootInfo === '') {
              rootInfo += statistic[list.title][item.title];
            } else {
              rootInfo += `, ${statistic[list.title][item.title]}`;
            }
          }
        });

        list.info = <span style={{ color: "red" }}>({rootInfo})</span>;
      } else if (!list.children && listTitleExists) {
        list.info = <span style={{ color: "red" }}>({statistic[list.title]})</span>;
      }
    }

    return list;
  }, [list, statistic]);

  if (hasChildren) {
    return (
      <>
        <NavItemRoot item={newList} isCollapse={isCollapse} active={active} open={open} onOpen={() => setOpen(!open)} />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(newList.children || []).map((item) => {
                const currentRoles = item?.role || ['admin'];
                if (!currentRoles.includes(role)) {
                  return null;
                }
                return (
                  <NavListSub key={item.title} list={item} />
                )
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={newList} active={active} isCollapse={isCollapse} />;
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
  list: PropTypes.object,
};

function NavListSub({ list }) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub key={item.title} item={item} active={getActive(item.path, pathname)} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} />;
}
