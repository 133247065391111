import axiosInstance from "src/utils/axios";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isLoading: false,
  error: null,
  order: null,
  orders: [],
  total: 0,
};

const ordersSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload.results;
      state.total = action.payload.total;
    },
  },
});

export default ordersSlice.reducer;
export const { startLoading, hasError } = ordersSlice.actions;

export function getOrders(query, page, limit, username, product) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosInstance.post('/order/history', {
        query,
        limit,
        page,
        username,
        product
      });
      // console.log(response.data)
      dispatch(ordersSlice.actions.getOrdersSuccess(response.data || []));
    } catch (error) {
      dispatch(ordersSlice.actions.getOrdersSuccess({
        results: [],
        total: 0
      }));
      dispatch(hasError(error));
    }
  };
}

export function deleteOrderHistory(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      await axiosInstance.delete('/order/history/' + id);
      // dispatch(getOrders());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteMultipeOrderHistory(ids) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      await axiosInstance.post('/order/history/delete', { ids });
      // dispatch(getOrders());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}