import axiosInstance from "src/utils/axios";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isLoading: false,
  error: null,
  contents: [],
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getContentsSuccess: (state, action) => {
      state.isLoading = false;
      state.contents = action.payload;
    },
    updateContentSuccess: (state, action) => {
      state.isLoading = false;
      let { id, content, page } = action.payload;
      //find by page
      let index = state.contents.findIndex((item) => item._id === page);
      if (index !== -1) {
        //find by id
        let index2 = state.contents[index].contents.findIndex((item) => item._id === id);
        if (index2 !== -1) {
          state.contents[index].contents[index2].content = content;
        }
      }

    },
  },
});

export const {
  startLoading,
  hasError,
  getContentsSuccess,
  updateContentSuccess,
} = settingSlice.actions;

export default settingSlice.reducer;

export const fetchContents = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosInstance.get("/setting/content");
    dispatch(getContentsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const updateContent = (id, data) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosInstance.put(`/setting/content/${id}`, { content: data });
    // console.log("response", response.data);
    dispatch(updateContentSuccess({ id, page: response?.data?.page, content: response?.data?.content }));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
}