import axios from '../../utils/axios';
import { dispatch } from '../store';

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isLoading: false,
  error: null,
  auctions: [],
  length: 0,
  auction: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
}

const slice = createSlice({
  name: 'auction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getAuctionsSuccess(state, action) {
      state.isLoading = false;
      state.auctions = action.payload.auctions || [];
      state.length = action.payload.length || 0;
    },

    // SET Item in Auction
    setItemInAuction(state, action) {
      const { items, auctionId } = action.payload;
      const index = state.auctions.findIndex((auction) => auction._id === auctionId);
      state.auctions[index].items = items;
    },


    //Remove Auction success
    removeAuctionSuccess(state, action) {
      state.isLoading = false;
      state.auctions = state.auctions.filter((auction) => auction._id !== action.payload);
    },

    //Remove Auctions success
    removeAuctionsSuccess(state, action) {
      const ids = action.payload;
      state.isLoading = false;
      state.auctions = state.auctions.filter((auction) => !ids.includes(auction._id));
    },

    // GET PRODUCT
    getAuctionSuccess(state, action) {
      state.isLoading = false;
      state.auction = action.payload;
    },
  }
})

export const { getAuctionsSuccess, getAuctionSuccess, removeAuctionsSuccess, removeAuctionSuccess, setItemInAuction } = slice.actions;
export default slice.reducer;

export function getAuctions(page, limit, query) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/auction/pagination/${page}/${limit}`, { query });
      // console.log(response.data);
      dispatch(slice.actions.getAuctionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAuction(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/auction/${id}/admin`);
      dispatch(slice.actions.getAuctionSuccess(response.data || null));
    } catch (error) {
      dispatch(slice.actions.getAuctionSuccess(null));
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAuctionCreate(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/auction/${id}/admin/create`);
      dispatch(slice.actions.getAuctionSuccess(response.data || null));
    } catch (error) {
      dispatch(slice.actions.getAuctionSuccess(null));
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeAuction(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/auction/delete/${id}`);
      dispatch(slice.actions.removeAuctionSuccess(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeAuctions(ids) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/auction/delete', { ids });
      dispatch(slice.actions.removeAuctionsSuccess(ids));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}